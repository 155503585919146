<!-- @format -->
<script lang="ts">
    import { getStoresContext } from "~/stores";
    import { getStorePagesFromFobs } from "../../configurations";
    import type { StoresKey } from "~/stores";

    export let request_store_key: StoresKey;
    export let nav_item: NavigationMenuItem;

    const { site_page, i18nOf, site_groupings, stores, reverse_router } = getStoresContext(request_store_key); 

    $: title = i18nOf(nav_item?.name_i18n);
    $: href = i18nOf(nav_item.link_i18n);
    $: target = nav_item?.link ? "_self" : "_blank";
    $: current = $site_page?.friendlyUrlPath;
    $: is_active = current == nav_item?.link;
    $: sub_pages = nav_item?.navigationMenuItems;
    const storesPageLink = $reverse_router.routePurpose('stores collection');
    const restaurantsPageLink = $reverse_router.routePurpose('custom1');

    let show = false;
    const handleToggle = () => (show = !show);
    $: nav_item = getStorePagesFromFobs(nav_item, $site_groupings, $stores, storesPageLink, restaurantsPageLink);
</script>

{#if nav_item?.navigationMenuItems?.length}
    <div class="menu-item">
        <button class="nav-button" on:click={handleToggle}>
            <span class="title">{title}</span>
            <span class="chevron" class:rotate={show}></span>
        </button>
        <div class="dropdown" class:show={show}>
            <a class="dropdown-row" {href} {target}>
                <span class="chevron-right"></span>
                <span class="dropdown-item">{title}</span>
            </a>
            {#each sub_pages as nav_child}
                <a class="dropdown-row" 
                   href={$reverse_router.routePage(nav_child?.link)}>
                    <span class="chevron-right"></span>
                    <span class="dropdown-item">{i18nOf(nav_child?.name_i18n)}</span>
                </a>
            {/each}
        </div>
    </div>
{:else}
    <a {href} {target}
        class="menu-item"
        class:active={is_active}>
            {title}
    </a>
{/if}

<style lang="scss">
    a {
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        color: var(--color-accent-black);
        &:hover {
            background-color: unset;
        }
    }
    .menu-item {
        display: flex;
        flex-direction: column;
        padding: 15px 19px 15px 24px;
        border-bottom: 1px solid rgba(51, 79, 49, .2)
        ;
        font-size: 14px;
        font-weight: bold;
        color: var(--color-accent-black);
        -webkit-tap-highlight-color: transparent;
        .title {
            font-weight: bold;
            cursor: pointer;
        }
        .icon {
            width: 9px;
            height: 5px;
            margin: 0 0 0 0px;
            transition: transform 0.5s;
            transform: rotate(180deg);
            -webkit-filter: invert(0);
            filter: invert(0);
        }
        .rotate {
            transform: rotate(0deg);
        }
        .dropdown {
            display: none;
            .icon-before {
                transform: rotate(90deg);
                margin: 0 10px 0 0;
            }
            .dropdown-item {
                font-size: 14px;
                color: var(--color-accent-black);
                padding: 0;
                &:hover {
                    background-color: transparent !important;
                }
            }
        }
        .show {
            display: block;
        }
    }

    .nav-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none;
        background: transparent;
        padding: 0;
        color: var(--color-accent-black);
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;

        .chevron {
            width: 5px;
            height: 5px;
            border: solid var(--color-accent-black);
            border-width: 0 2px 2px 0;
            display: inline-block;
            transform: rotate(45deg);
            transition: transform 0.3s ease;
            
            &.rotate {
                transform: rotate(-135deg);
            }
        }
    }

    .dropdown-row {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        text-decoration: none;
        color: inherit;

        &:hover {
            background-color: transparent !important;
        }
    }

    .chevron-right {
        width: 6px;
        height: 6px;
        border: solid var(--color-accent-black);
        border-width: 0 2px 2px 0;
        display: inline-block;
        transform: rotate(-45deg);
        margin: 0 10px 0 0;
    }

    .dropdown-item {
        font-size: 14px;
        color: var(--color-accent-black);
        padding: 0;
    }
</style>