<!-- @format -->
<script lang="ts">
    import { getStoresContext } from "~/stores";
    import type { StoresKey } from "~/stores";
    import { onMount } from "svelte";
    import { createEventDispatcher } from "svelte";
    import { getStorePagesFromFobs } from "../../configurations";
    export let request_store_key: StoresKey;
    export let dark_font=true;
    export let nav_item: NavigationMenuItem;

    const { site_page, i18nOf, locale, reverse_router, site_groupings, stores } = getStoresContext(request_store_key); 
    const dispatch = createEventDispatcher();
    const storesPageLink = $reverse_router.routePurpose('stores collection');
    const restaurantsPageLink = $reverse_router.routePurpose('custom1');
    $: title = i18nOf(nav_item?.name_i18n);
    $: href = nav_item?.link? $reverse_router.routePage(nav_item?.link) : nav_item?.url;
    $: target = nav_item?.link ? "_self" : "_blank";
    $: current = $site_page?.friendlyUrlPath;
    $: is_active = current == nav_item?.link;
    $: sub_pages = nav_item?.navigationMenuItems;
    $: sub_pages_filtered =  (sub_pages|| []).filter(
        (item) => !item.excluded_locales?.some((x) => x == $locale)
    );
    let show = false;
    let isActive = false;
    let fontColor = 'var(--color-accent-black)';

    $: {
        fontColor = dark_font || show ? 'var(--color-accent-black)' : 'var(--color-accent-white)';
        nav_item = getStorePagesFromFobs(nav_item, $site_groupings, $stores, storesPageLink, restaurantsPageLink);
    }  
    
    function openDropdown(open: boolean ) {
        dispatch("openDropdown", open);
    }
    function focus() {
        show = true;
        openDropdown(show);
    }
    function blur() {
        show = false;
        openDropdown(show);
    }
    
    function handleClick() {
        const newState = !show;
        // Dispatch event to close other dropdowns
        window.dispatchEvent(new CustomEvent('closeOtherDropdowns', { 
            detail: { currentId: nav_item.link || nav_item.url } 
        }));
        show = newState;
        isActive = newState;
        // Dispatch dropdown state to parent
        dispatch('dropdownStateChange', newState);
    }
    
    onMount(() => {
        // Existing closeHandler for other dropdowns
        const closeHandler = (e: Event) => {
            const customEvent = e as CustomEvent<{currentId: string}>;
            if (customEvent.detail.currentId !== (nav_item.link || nav_item.url)) {
                show = false;
                isActive = false;
                dispatch('dropdownStateChange', false);
            }
        };

        // Click outside handler
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.nav-item')) {
                show = false;
                isActive = false;
                dispatch('dropdownStateChange', false);
            }
        };

        // Escape key handler
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                show = false;
                isActive = false;
                dispatch('dropdownStateChange', false);
            }
        };

        window.addEventListener('closeOtherDropdowns', closeHandler);
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleEscKey);

        // Cleanup
        return () => {
            window.removeEventListener('closeOtherDropdowns', closeHandler);
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleEscKey);
        };
    });

    function handleNavigation() {
        if (nav_item?.link || nav_item?.url) {
            if (nav_item.link) {
                window.location.href = $reverse_router.routePage(nav_item.link);
            } else {
                window.location.href = nav_item.url;
            }
        }
    }
</script>

{#if sub_pages_filtered?.length} 
    <li class="nav-item"
        class:active={is_active}
        style="--font-color:{fontColor};">
        <button class="nav-button" 
                class:active={isActive}
                on:click={handleClick}>
            {title}
            <span class="chevron" class:rotate={show}></span>
        </button>
        <ul class='dropdown' class:show={show}>
            <a class="dropdown-title" href={href} target={target}>
                {title}
                <span class="chevron-static"></span>
            </a>
            {#each sub_pages_filtered as nav_child}
                <li class="dropdown-item">
                    <a 
                        href={nav_child?.link? $reverse_router.routePage(nav_child?.link) : nav_child?.url} 
                        target={nav_child?.link ? "_self" : "_blank"}> 
                        {i18nOf(nav_child?.name_i18n)}
                    </a>
                </li>
            {/each}
        </ul>
    </li>
{:else}
    <li class="nav-item"
        class:active={is_active} 
        style="--font-color:{fontColor};">
        <button class="nav-button"
                on:click={handleNavigation}>
            {title}
        </button>
    </li>
{/if}

<style lang="scss">
    .active {
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--font-color);
        }
    }
    .nav-item {
        position: relative;
        height: 100%;
        
        .nav-button {
            all: unset;
            width: 100%;
            cursor: pointer;
            color: var(--font-color);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0;
            gap: 4px;
            white-space: nowrap;
            transition: color 0.3s ease;
            text-decoration: none !important;
            
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -2px;
                left: 0;
                background-color: var(--font-color);
                transform: scaleX(0);
                transition: transform 0.2s ease-out;
            }
            
            &:hover::after {
                transform: scaleX(1);
            }

            .chevron {
                width: 5px;
                height: 5px;
                border: solid var(--font-color);
                border-width: 0 2px 2px 0;
                display: inline-block;
                transform: rotate(45deg);
                transition: transform 0.3s ease;
                
                &.rotate {
                    transform: rotate(-135deg);
                }
            }
        }

        .dropdown {
            position: absolute;
            width: max-content;
            left: 0;
            top: calc(100% + 2px);
            padding: 32px 100vw 40px 100vw;
            margin: 0 -100vw;
            background: var(--mobile-background-color);
            box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.2);
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease, visibility 0.3s ease;
            &.show {
                opacity: 1;
                visibility: visible;
            }

            a {
                text-decoration: none !important;
                cursor: pointer;
                color: var(--color-accent-black);
                
                &:hover {
                    text-decoration: none !important;
                    background-color: transparent !important;
                    color: var(--color-brand-primary);
                }
            }

            .dropdown-title {
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 0 0 10px 0;
                font-size: 18px;
                font-weight: bold;
                
                .chevron-static {
                    width: 6px;
                    height: 6px;
                    border: solid var(--color-accent-black);
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    transform: rotate(-45deg);
                }
            }

            .dropdown-item {
                font-size: 14px;
                padding: 10px 0;
                background-color: transparent !important;

                a {
                    &:hover {
                        background-color: transparent !important;
                        color: var(--color-brand-primary);
                    }
                }

                &:before {
                    display: inline-block;
                    transform: rotate(90deg);
                    content: url("/theme/ainoa2024/images/arrow-down.svg");
                    margin: 0 5px 0 0;
                }

                &:hover:before {
                    filter: var(--filter-color-brand-primary);
                }
            }
        }
        .nav-button {
            &.active {
                color: var(--color-accent-black) !important;
            }
        }
    }
</style>