<!-- @format -->
<script lang="ts">
  import { type Alternate } from "~/metadata";
  import type { StoresKey } from "~/stores";
  import { getStoresContext } from "~/stores";
  import { onMount } from "svelte"; 
  import { ReverseRouter } from "~/reverse-router";
  import { getFragmentConfigOr, valueGetters } from "~/components/field-utils";
  import { getOpeningHoursRangesAndStatusByDate } from "~/util/date-n-time";
  import NavItem from "./NavItem.svelte";
  import NavItemMobile from "./NavItemMobile.svelte";
  
  export let fragment: Fragment;
  export let request_store_key: StoresKey;

  const stores_context = getStoresContext(request_store_key);
  const { nav_menus, reverse_router, alternate, locale, site, opening_hours, T } = stores_context;

  let mainBanner: HTMLElement | null;  
  let subpageBanner: HTMLElement | null;
  let bannerLoaded = false;

  onMount(async () => {
    mainBanner = document.querySelector(".main-banner .veil");
    subpageBanner = document.querySelector(".subpage-banner .overlay-1");
    if(subpageBanner?.classList.contains('d-none')) subpageBanner = null;
    bannerLoaded = true;
  });

  let mobileBackgroundColor = getFragmentConfigOr("mobileBackgroundColor", fragment, "var(--colorActionSecondaryDefault)");
  let backgroundColorBurger = 'transparent';
  let backgroundColor = 'transparent';
  $: titleOne = t('header.title-logo-1');
  $: titleTwo = t('header.title-logo-2');

  let openMobileMenu = false;
  let isDropdownOpen = false;
  let fontColor = 'var(--color-accent-black)';
  let today: { ranges: string[]; status: string } | null;
  const languages: Record<string, string> = {
    "fi-FI": "Suomeksi",
    "en-US": "English",
  };

  $: {  
    if ($opening_hours && $locale) {
      today = getOpeningHoursRangesAndStatusByDate(
        $opening_hours.shoppingCenterOpeningHours,
        $opening_hours.exceptionalOpeningHours,
        new Date(),
        $locale,
      );
    }
    // Only process background colors after the component has mounted and checked for banners
    if (bannerLoaded) {
      if (mainBanner || subpageBanner) {
        const banner = mainBanner || subpageBanner;
        // Only process if banner is not null
        if (banner) {
          const style = window.getComputedStyle(banner);
          const backgroundBanner = style.getPropertyValue('background');
          backgroundColorBurger = getFragmentConfigOr(
            'backgroundColorBurger', 
            fragment, 
            backgroundBanner && backgroundBanner.includes('gradient(') 
              ? `${backgroundBanner.split('gradient(').pop()?.split(')')[0]})`
              : 'transparent'
          );
        }
        // Keep header background transparent when banner is present
        backgroundColor = 'transparent';
      } else {
        // Only use fragment values or fallbacks when no banner is found
        backgroundColorBurger = getFragmentConfigOr('backgroundColorBurger', fragment, "transparent");
        backgroundColor = getFragmentConfigOr('backgroundColor', fragment, 'transparent');
      }
    }
    
    fontColor = backgroundColor === 'transparent' && !isDropdownOpen? 'var(--color-accent-white)' : 'var(--color-accent-black)';
  }
  let primaryFiltered: NavigationMenuItem[];
  $: primary = $nav_menus.find((nav) => nav.navigationType == "Primary");
  $: primaryFiltered = (primary?.navigationMenuItems || []).filter(
    (item) => !item.excluded_locales?.some((x) => x == $locale)
  );
  $: home_page_nav_item = $reverse_router.routePurpose("home page");
  $: logo = $site && $site.logo;
  $: opposite_lang = $locale == "fi-FI" ? "en-US" : "fi-FI";

  function handleDropdownStateChange(event: CustomEvent<boolean>) {
    isDropdownOpen = event.detail;
    // Update background color and font color only in desktop view
    if (window.innerWidth >= 768) {
      backgroundColor = isDropdownOpen ? 'var(--color-brand-primary-lighten-6)' : 'transparent';
      fontColor = isDropdownOpen ? 'var(--color-accent-black)' : 'var(--color-accent-white)';
      darkFont = isDropdownOpen;
    }
  }

  function hrefFor( locale: string,alternate: readonly Alternate[],reverse_router: Readonly<ReverseRouter>) {
    const alt = alternate.find((x) => x.locale == locale);
    return alt
      ? alt.href
      : reverse_router.findPurposeRoute("home page", locale);
  }
  $: darkFont = isDropdownOpen;
  $:t = $T;
</script>

<header style="--background-color:{backgroundColor}; 
  --burger-bckground:{backgroundColorBurger};
  --mobile-background-color:{mobileBackgroundColor};"
  class:color-open-background={isDropdownOpen && window.innerWidth >= 768}>
  <div class="fluid-container">
    <nav class="{darkFont? 'dark-border':'light-border'} main-nav">
      <a class="logo-container" href={home_page_nav_item}>
        <div class="{darkFont? 'dark-font':'white-font'} logo-title"> {titleOne} </div>
        <img class='{darkFont? 'revert-color':'invert-color'} logo' src={logo} alt="Ainoa logo"/>
        <div class="{darkFont? 'dark-font':'white-font'} logo-title"> {titleTwo} </div>
      </a>
      <a class="{darkFont? 'dark-font':'white-font'} header-link opening-hours-link" href={$reverse_router.routePurpose('opening hours')}>
        {#if today && today.ranges.length}
          {t`header.todayOpen`}
          {#each today.ranges as range}
            {#each range.split("–") as o, i}
              <span>{`${o.toString().padStart(2, '0')}:00`}</span>
              {#if i == 0}
                <span>-</span>
              {/if}
            {/each}
          {/each}
        {:else}
          {t`header.todayClosed`}
        {/if}
      </a>
      <button class="burger-icon" on:click={() => openMobileMenu = true}>
        <span class="burger-line"></span>
        <span class="burger-line"></span>
        <span class="burger-line"></span>
      </button>
    </nav>
    {#if primaryFiltered}
      <div class="white-font nav-items d-none d-md-flex align-items-center" 
           class:has-open-dropdown={isDropdownOpen}>
        <ul class="nav-grid">
          {#each primaryFiltered as nav_item}
            <NavItem 
                on:dropdownStateChange={handleDropdownStateChange}
                dark_font={darkFont} 
                {nav_item} 
                {request_store_key}
            />
          {/each}
        </ul>
        <a class="{darkFont? 'dark-font':'white-font'} header-link language-toggle {$locale === 'en-US' ? 'lowercase' : ''}" 
           title={languages[opposite_lang]}
           href={hrefFor(opposite_lang, $alternate, $reverse_router)}>
          {languages[opposite_lang]}
        </a>
      </div>
      <div class="dropdown-mobile" class:open={openMobileMenu}>
        <div class="mobile-header" class:open={openMobileMenu}>
          <a class="logo-container" href={home_page_nav_item}>
            <div class="logo-title" class:dark-font={openMobileMenu}>{titleOne}</div>
            <img class="logo" class:revert-color={openMobileMenu} src={logo} alt="Ainoa logo"/>
            <div class="logo-title" class:dark-font={openMobileMenu}>{titleTwo}</div>
          </a>
          <button class="button-close" on:click={() => openMobileMenu = false}>
            <span class="close-icon">✕</span>
          </button>
        </div>
        <div class="mobile-wrapper">
          {#each primaryFiltered as nav_item}
            <NavItemMobile {nav_item} {request_store_key}/>
          {/each}
          <a
            class="langs d-lg-none d-flex dark-font language-toggle {$locale === 'en-US' ? 'lowercase' : ''}"
            title={languages[opposite_lang]}
            href={hrefFor(opposite_lang, $alternate, $reverse_router)}
            >{languages[opposite_lang]}
          </a>
        </div>
      </div>
    {/if} 
  </div>
</header>

<style lang="scss">
  header {
    position: absolute;
    width: 100%;
    z-index: 11;
    background-color: var(--background-color);
    padding: 0 82px;
    transition: background-color 0.3s ease;

    @media screen and (max-width: 991px) {
      padding: 0;
    }

    // Remove default link styling
    a {
      text-decoration: none;
      &:hover {
        background-color: unset;
      }
    }

    &.color-open-background {
      background-color: var(--mobile-background-color) !important;
    }
  }

  // Main Navigation
  .main-nav {
    position: relative;
    display: grid;
    grid-template-columns: 210px 1fr 210px;
    align-items: center;
    padding: 20px 0;
    border-bottom: 2px solid transparent;
    
    &.dark-border {
      border-bottom-color: rgba(51, 79, 49, 0.2);
    }
    
    &.light-border {
      border-bottom-color: rgba(255, 255, 255, 0.2);
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 20px 20px;
    }

    @media (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
    }
  }

  // Burger Icon
  .burger-icon {
    position: absolute;
    right: 6px;
    top: 9px;
    cursor: pointer;
    padding: 10px;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 36px;
    height: 36px;
    
    @media (max-width: 767px) {
      display: flex;
    }

    .burger-line {
      display: block;
      width: 20px;
      height: 2px;
      background-color: var(--color-accent-white);
      transition: transform 0.2s ease;
    }
  }

  // Logo Container
  .logo-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 0.5rem;
    grid-column: 2;
    justify-self: center;
    align-items: end;

    @media (max-width: 767px) {
      grid-column: 1;
    }

    @media (max-width: 375px) {
      grid-template-columns: 1fr;
    }

    .logo {
      margin: 0 40px;
      height: 21px;

      @media (max-width: 375px) {
        margin: 0 auto;
      }

      @media screen and (max-width: 991px) {
        margin: 0 8px;
      }

      &.invert-color {
        filter: invert(1);
      }
      
      &.revert-color {
        filter: invert(0);
      }
    }

    .logo-title {
      font-family: var(--font-main);
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      transition: font-size 0.2s ease;
      
      &:first-child {
        text-align: right;
      }
      
      &:last-child {
        text-align: left;
        width: fit-content;
      }

      &:hover {
        transform: scale(1.05);
      }

      @media screen and (max-width: 991px) {
        font-size: 9px;
      }

      &.dark-font {
        color: var(--color-accent-black);
      }
      
      &.white-font {
        color: var(--color-accent-white);
      }

      @media (max-width: 375px) {
        display: none;
      }
    }
  }

  // Navigation Items
  .nav-items {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    transition: border-bottom 0.3s ease;
    border-bottom: 2px solid transparent;
    
    &.has-open-dropdown {
      border-bottom: 2px solid rgba(51, 79, 49, 0.2);
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 0 20px;
      font-size: 12px;
    }
  }

  // Nav Grid
  ul.nav-grid {
    display: flex;
    justify-content: space-around;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: 1200px) {
      display: flex;
      flex-wrap: nowrap;
      
     > :global(li.nav-item) {
        width: 100%;
      }
    }

  @media (min-width: 768px) and (max-width: 1200px) {
    gap: 0.5rem;
    padding: 0 0.5rem;
  }
}

  // Opening Hours Link
  .opening-hours-link {
    grid-column: 3;
    justify-self: end;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;

    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
      font-size: 12px;
    }

    &::after {
      content: '›';
      font-family: Arial, sans-serif;
      font-size: 14px;
      line-height: 1;
      transition: transform 0.2s ease;

      @media screen and (min-width: 768px) and (max-width: 1200px) {
        font-size: 12px;
      }
    }


    &:hover::after {
      transform: translateX(4px);
    }
  }

  // Mobile Menu
  .dropdown-mobile {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #E6F0EE;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    
    &.open {
      transform: translateX(0);
    }
  }

  .mobile-header {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 2rem;
    background: var(--color-brand-primary);
    
    &.open {
      background: #E6F0EE;
    }

    .logo-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 72px);

      .logo {
        @media (max-width: 375px) {
          justify-self: center;
        }
      }
    }

    .button-close {
      position: absolute;
      right: 1rem;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: white;
      border: none;
      cursor: pointer;
      padding: 0;
      -webkit-tap-highlight-color: transparent;
      -webkit-appearance: none;
      color: inherit;
    }

    .close-icon {
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      line-height: 1;
      font-family: Arial, sans-serif;
    }
  }

  // Language Toggle
  .language-toggle {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 24px;
    font-size: 14px;
    text-decoration: none;
    
    &.lowercase {
      text-transform: lowercase;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding-right: 0;
      font-size: 12px;
    }
  }

  // Utility Classes
  .dark-font {
    color: var(--color-accent-black);
  }

  .white-font {
    color: var(--color-accent-white);
  }

  .mobile-wrapper {
    overflow-y: auto;
    max-height: calc(100vh - 70px);
    padding-bottom: 20px;
  }
</style>